import React from "react";
import Image from "next/image";
import { handleFixImageUrl } from "helpers";
import { useTranslation } from "react-i18next";
import src from "assets/images/logo.svg";
import { APIResponseCollection } from "types/models";
import { TNextClientComponent } from "types/next";
import ServiceItem from "components/custom/ServiceItem";
import Icon from "components/tailwind-ui/Icon";
import { styles } from ".";
type TProps = {
  socials?: {
    accessor: string;
    href: string;
  }[];
  services?: APIResponseCollection<"api::service.service">;
};
const NavbarMobileMenu: TNextClientComponent<TProps> = props => {
  const {
    socials = [],
    services
  } = props;
  const {
    t
  } = useTranslation();
  return <div className={styles.container} data-sentry-component="NavbarMobileMenu" data-sentry-source-file="NavbarMobileView.tsx">
      <div className={styles.head}>
        <Image src={handleFixImageUrl(src)} alt="logo" width={120} height={60} data-sentry-element="Image" data-sentry-source-file="NavbarMobileView.tsx" />
      </div>
      <div className={styles.body}>
        {[...(services?.data ?? [])]?.sort((a, b) => {
        if (a?.attributes?.sequence_number < b?.attributes?.sequence_number) {
          return -1;
        }
        return 1;
      })?.map((service, index) => <li key={`service-${service.id}-${index}`} className="list-none">
              <ServiceItem service={service} isOpen={service?.attributes?.title === "About"} />
            </li>)}
      </div>
      <div className="p-6">
        <h3 className={styles.heading}>{t("label.custom.contact")}</h3>
        <div className={styles.socials.list}>
          {[...(socials ?? [])]?.map(social => <a key={social.accessor} href={social.href} target="_blank" rel="noreferrer">
              <Icon type="brands" accessor={social.accessor as any} className={styles.socials.icon} />
            </a>)}
        </div>
      </div>
    </div>;
};
export default NavbarMobileMenu;