export type NestedKeyOf<TObject extends object> = {
  [Key in keyof TObject & string]: TObject[Key] extends object
    ? `${Key}.${NestedKeyOf<TObject[Key]>}`
    : Key;
}[keyof TObject & string];

export const calculateNestedKeys = <T extends { [key: string]: any }>(
  obj: T,
): NestedKeyOf<T>[] => {
  function flattenHelper(obj: T, prefix: string): NestedKeyOf<T>[] {
    return (Object.keys(obj) as NestedKeyOf<T>[]).reduce(
      (acc, key): NestedKeyOf<T>[] => {
        const fullPath = prefix ? (`${prefix}.${key}` as NestedKeyOf<T>) : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          return [...acc, ...flattenHelper(obj[key], fullPath)];
        } else {
          return [...acc, fullPath];
        }
      },
      [] as NestedKeyOf<T>[],
    );
  }

  return flattenHelper(obj, '');
};
