const styles = {
  container: "font-body text-sm flex flex-col h-full bg-gray-300",

  head: "p-6 bg-primary-800",
  body: "p-6 flex flex-col gap-y-4 flex-grow overflow-scroll",

  navigation: {
    item: "hover:text-primary-600 duration-100",
  },

  socials: {
    list: "flex flex-row gap-x-4",
    icon: "hover:text-primary-600 cursor-pointer duration-100",
  },

  heading: "uppercase text-gray-400 text-xs mb-3",

  foot: {
    container:
      "hidden md:block col-span-0 md:col-span-2 fixed p-6 pb-0 rounded-md bg-gray-300 shadow-lg",
    heading: "text-xs uppercase text-gray-500 w-full border-b pb-2",
    list: "flex flex-col gap-y-4 overflow-scroll h-[66vh] border-b py-4 pb-24 w-[20rem]",
  },
};

export default styles;
