const base = {
  container: "relative z-50",

  background: {
    container: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
    transition: {
      enter: "ease-in-out duration-500",
      enterFrom: "opacity-0",
      enterTo: "opacity-100",
      leave: "ease-in-out duration-500",
      leaveFrom: "opacity-100",
      leaveTo: "opacity-0",
    },
  },

  layout: {
    container: "fixed inset-0 overflow-hidden",
    content: "absolute inset-0 overflow-hidden",
    inner: "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10",
  },

  panel: {
    container: "pointer-events-auto w-screen bg-white",
    transition: {
      enter: "transform transition ease-in-out duration-500 sm:duration-700",
      enterFrom: "translate-x-full",
      enterTo: "translate-x-0",
      leave: "transform transition ease-in-out duration-500 sm:duration-700",
      leaveFrom: "translate-x-0",
      leaveTo: "translate-x-full",
    },
  },
};

const xs: TSlideOverOverlayStyles = {
  panel: {
    container: "sm:max-w-xs",
  },
};

const sm: TSlideOverOverlayStyles = {
  panel: {
    container: "sm:max-w-sm",
  },
};

const md: TSlideOverOverlayStyles = {
  panel: {
    container: "sm:max-w-md",
  },
};

const lg: TSlideOverOverlayStyles = {
  panel: {
    container: "sm:max-w-2xl",
  },
};

const xl: TSlideOverOverlayStyles = {
  panel: {
    container: "sm:max-w-4xl",
  },
};

const styles = {
  base,

  xs,
  sm,
  md,
  lg,
  xl,
};

export type TSlideOverOverlayStyles = any;
export default styles;
