/*

https://tailwindui.com/components/application-ui/overlays/modals

*/

export type { TSlideOverOverlayStyles } from './SlideOverOverlay.styles';

export { default } from './SlideOverOverlay';
export { default as styles } from './SlideOverOverlay.styles';
