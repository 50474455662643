const styles = {
  container: "flex flex-row gap-x-4 pr-8",

  head: "",
  foot: "flex flex-col gap-y-1",

  image: "h-12 aspect-video bg-center bg-cover rounded-sm",
  heading: "text-sm",
  description: "text-xs leading-5 text-gray-800",
};

export default styles;
